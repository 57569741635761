import React from "react"
import {Row, Column} from 'react-foundation';
import { FaTachometerAlt } from 'react-icons/fa';
import AdminMenu from './AdminMenu';

export  default function AdminUsers(props) {
    return (
      <>
        <Row className="admin-page">
            <Column medium={2}>
                <AdminMenu/>
            </Column>
            <Column medium={1}>
            </Column>
            <Column medium={9}>
                {props.children}
            </Column>
        </Row>
      </>
    )

}
