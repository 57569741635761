import React, {Component} from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('modals');
class AfterModal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.classList.add("after-modal");

  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }
  render() {
    if(!this.props.isOpen) return null;
    return ReactDOM.createPortal(
        (
            <div className={this.props.className?this.props.className:null}>
                {React.Children.toArray(this.props.children).map((child,index)=>
                    <div key={index}>
                        { React.cloneElement(child, {
                            isOpen              : this.props.isOpen,
                            toggleModal         : this.props.toggleModal
                        })}
                    </div>
                )}
            </div>
        ),
      this.el
    );
  }
}
export default AfterModal;
