import React, {useState} from "react"
import {Row, Column} from 'react-foundation';
import { FaPlusCircle } from 'react-icons/fa';
import { Link} from "react-router-dom";

import AdminMenu from './../AdminMenu';
import AdminPage from './../AdminPage';
import AdminUserProfile from './AdminUserProfile';
import AdminUserWrittenTestHistory from './AdminUserWrittenTestHistory';
import {fetchPagedResource} from "./../../../utils/dataAccess";


export  default function AdminUsers(props) {
    const userId = props.match.params.id;

    const [isLoading, setIsLoading] = useState(false);

    console.log("User details props", props);

    function loadUserData() {
        // setIsLoading(true);
        // fetchPagedResource("users")
        // .then(data => {
        //     setIsLoading(false);
        //     console.log("USERS", data);
        //     setUsers(data['hydra:member']);
        // })
    }

    // if(!isLoading && users.length === 0) {
    //     loadUsers();
    // }

    return (
      <>
        <AdminPage>
            <h1>User Details for User ID: {userId}</h1>
            <Row>
                <Column small={12}>
                    <AdminUserProfile id={userId}/>
                </Column>
            </Row>
            <Row>
                <Column small={12}>
                    <AdminUserWrittenTestHistory id={userId}/>
                </Column>
            </Row>
        </AdminPage>
      </>
    )

}
