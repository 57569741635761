import React from "react"
import {Row, Column} from 'react-foundation';
import { FaTachometerAlt } from 'react-icons/fa';
import { Link} from "react-router-dom";


function AdminMenuLink(props) {
    const isActive = (()=>{
        return window.location.pathname === props.to;
    })();

    return (
        <li className={isActive ? "active" : ""}>
            <Link to={props.to}>{props.children}</Link>
        </li>
    );

}

export  default function AdminMenu(props) {
    const Link = AdminMenuLink;
    return (
        <nav className="admin-menu">
            <ul>
                <Link to="/admin/users">
                    Users
                </Link>

                <Link to="/admin/courses">
                    Courses
                </Link>

            </ul>
        </nav>
    )

}
