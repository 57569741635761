import React from "react"
import {Row, Column} from 'react-foundation';
import { FaTachometerAlt } from 'react-icons/fa';
import AdminMenu from './AdminMenu';
import ApplicationFrame from "./../ApplicationFrame";

export  default function NotAuthorized(props) {
    return (
        <>
            <h1>Not Authorized</h1>
        </>
    )
}
