import React, {useState} from "react"
import {Row, Column, Progress, Button} from 'react-foundation';
import moment from "moment";
import { Link} from "react-router-dom";

import {createResource, fetchResource} from "./../../utils/dataAccess";
import WrittenTestSection from './WrittenTestSection';

export  default function WrittenTest(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [isInitiated, setIsInitiated] = useState(false);
    const [writtenTest, setWrittenTest] = useState({});
    const [isGrading, setIsGrading] = useState(false);
    const [grade, setGrade] = useState(0);

    const [sectionIndex, setSectionIndex] = useState(0);

    if(!isInitiated) {
        setIsInitiated(true);
        setTimeout(()=> {
            createResource("written_course/"+props.match.params.id+"/user_instance", {})
                .then(data => {
                    let userWrittenCourseId = data.userWrittenCourse;
                    return fetchResource("user_written_courses/"+userWrittenCourseId);
                })
                .then(response=>{
                    setWrittenTest(response);
                    setIsLoading(false);
                })
        },50);
    }

    function nextSection() {
        let newSectionIndex = sectionIndex+1;

        // Grading ...
        if(newSectionIndex > 0 && newSectionIndex > writtenTest.sections.length) {
            setIsGrading(true);
            createResource("user_written_courses/"+writtenTest.id+"/grade",{})
            .then(data=>{
                setGrade(data.grade);
                setIsGrading(false);
            });
        }

        setSectionIndex(newSectionIndex);
    }

    function refresh() {
        return createResource("written_course/"+props.match.params.id+"/user_instance", {})
            .then(data => {
                let userWrittenCourseId = data.userWrittenCourse;
                return fetchResource("user_written_courses/"+userWrittenCourseId);
            })
            .then(response=>{
                setWrittenTest(response);
                setIsLoading(false);
            })
    }

    function retakeCourse() {
        setIsLoading(true);
        setSectionIndex(0);
        refresh();
    }

    if(isLoading) {
        return(
            <h3 className="loading" style={{marginTop:30}}>Loading Course...</h3>
        );
    }


    return(
      <Row>
        <Column small={12}>
          <div className="breadcrumb">
              <Link to={'/'}>{"< "}&nbsp; Back to Course List</Link>
          </div>
          <div className="card written-test">
             <Row className="card-row" className="hide-for-small-only">
                <Column medium={9} small={12}>
                  <h1 className="lesson-title" dangerouslySetInnerHTML={{__html: writtenTest.name}}/>
                </Column>
                <Column medium={3} className="hide-for-small-only">
                  <Row>
                    <Column small={12} className="text-right">
                      <div className="card-title percentage" >
                        <span>
                          {writtenTest.progress}% Complete
                          <Progress min={0} max={100} value={writtenTest.progress} className="progress-bar" />
                          <Row>
                            <Column small={4} className="percentage-laebel text-left">0</Column>
                            <Column small={4} className="percentage-laebel text-center">50</Column>
                            <Column small={4} className="percentage-laebel text-right">100</Column>
                          </Row>
                        </span>
                      </div>
                    </Column>
                  </Row>
                </Column>
              </Row>
              {sectionIndex == 0 &&
                <div className="lesson-content">
                  <h2>Course + Written Test</h2>
                  <div className="lesson-overview">
                    <Row>
                      <Column small={12} medium={6}>
                        <h3>Breakdown:</h3>
                        <ul className="breakdown">
                        { writtenTest.sections.map( (section) =>
                          <li key={section["@id"]}>
                            {section.name.replaceAll("&amp;","&")} - {section.has_video ? 'Video and Quiz' : 'Quiz'}
                          </li>
                        )}
                        </ul>
                      </Column>
                    </Row>
                  </div>

                  <Button className="card-button" onClick={nextSection}>Start Course</Button>
                </div>
              }
              {sectionIndex > 0 && sectionIndex <= writtenTest.sections.length &&
                <WrittenTestSection
                    section={writtenTest.sections[sectionIndex-1]}
                    triggerBack={()=>{setSectionIndex(sectionIndex-1)}}
                    triggerNext={nextSection}
                    refresh={refresh}
                />
              }
              {sectionIndex > 0 && sectionIndex > writtenTest.sections.length &&
                  <Row>
                    <Column small={12} className="text-center">
                      <h2 style={{marginBottom:0}}>Your Grade:<br/>
                      { isGrading &&
                        <span className="grade">Grading..</span>
                      }
                      { !isGrading &&
                        <span className="grade">
                          { grade  < 70 &&
                            <span className="danger">
                              {grade}%
                            </span>
                          }
                          { grade >= 70 && 
                            <span className="success">
                              {grade}%
                            </span>
                          }
                        </span>
                      }
                      </h2>
                      { !isGrading &&
                        <Row className="next-steps">
                          <Column small={12} className="text-center">
                            { grade < 70 &&
                              <div className="retake">
                                <p className="retake">To pass this course, you need a minimum score of 70%</p>
                                <Button className="card-button" onClick={retakeCourse}>Retake Course</Button>
                              </div>
                            }
                            { grade >= 70 &&
                              <div className="return-to-dash">
                                <p className="retake">Congratulations! You have successfully passed this course.</p>
                                <Button className="card-button" onClick={(e)=>{ props.history.push('/');}}>Return to Dasboard</Button>
                              </div>
                            }
                          </Column>
                        </Row>
                      }
                    </Column>
                  </Row>
              }
            </div>
        </Column>
      </Row>
    )

}
