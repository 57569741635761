import React, {useState} from "react"
import {Row, Column} from 'react-foundation';
import { Link } from "react-router-dom";
import {fetchResource, updateResource} from "./../utils/dataAccess";
import { useForm } from "react-hook-form";

export  default function UserProfile(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [isInitiated, setIsInitiated] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const { register, formState: { errors }, handleSubmit, reset } = useForm();

    if(!isInitiated) {
        setIsInitiated(true);
        fetchResource("users/"+props.user.id)
            .then(data=>{
                setUserProfile(data);
                setIsLoading(false);
            });
    }

    const onSubmit = (data) => {
        if(isSaving) return false;
        if(data.password != data.passwordConfirm) {
            alert("Passwords do not match");
            return false;
        }
        setIsSaving(true);
        updateResource("users/"+props.user.id,{
            previousPassword: data.previousPassword,
            password: data.password
        }).then(response=>{
            alert("Password Change Saved");
            reset();
            setIsSaving(false);
        }).catch(error=>{
            console.log("err", error);
            if(typeof error !== "undefined") {
                alert(error);
            }
            else {
                alert("An error occurred");
            }
            reset();
            setIsSaving(false);
        });
    }

    return(
    <>
        <Row>
            <Column small={12}>
                <div className="breadcrumb">
                    <Link to={'/'}>{"< "}&nbsp; Back to Course List</Link>
                </div>
            </Column>
        </Row>
        <Row>
            <Column smal={12}>
                <h1>User Profile</h1>
            </Column>
        </Row>
        <Row>
            {isLoading ? (
                <Column small={12} >
                    <h3>Loading User Profile...</h3>
                </Column>
            ):
            (
                <Column small={12} >
                    <div className="card">
                        <form onSubmit={handleSubmit(onSubmit)} className="form hook-form">
                            <Row>
                                <Column small={12}>
                                    <label>Your Name:</label>
                                    <input
                                        type="text"
                                        disabled
                                        value={userProfile.firstName + " " + userProfile.lastName}
                                    />
                                </Column>

                                <Column small={12}>
                                    <label>Username:</label>
                                    <input
                                        type="text"
                                        disabled
                                        value={userProfile.username}
                                    />
                                </Column>

                                <Column small={12}>
                                    <label>Email:</label>
                                    <input
                                        type="text"
                                        disabled
                                        value={userProfile.email}
                                    />
                                </Column>

                                <Column small={12}>
                                    <label>Current Password:</label>
                                    <input
                                        type="password"
                                        {...register('previousPassword', {
                                            required: "Required",
                                            minLength: 4
                                        })}
                                    />
                                    {errors.previousPassword && <p className="error">Your current password is required.</p>}
                                </Column>
                                <Column small={12}>
                                    <label>New Password:</label>
                                    <input
                                        type="password"
                                        {...register('password', {
                                            required: "Required",
                                            minLength: 8
                                        })}
                                    />
                                    {errors.password && <p className="error">A password with at least 8 characters is required.</p>}
                                </Column>
                                <Column small={12}>
                                    <label>New Password (Confirm):</label>
                                    <input
                                        type="password"
                                        {...register('passwordConfirm', {
                                        })}
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column small={12}>
                                    {isSaving ? (
                                        <input type="button" disabled className="button button-primary" value="Saving..."/>
                                    ):
                                    (
                                        <input type="submit" className="button button-primary" value="Update Password"/>
                                    )}
                                </Column>
                            </Row>
                        </form>
                    </div>
                </Column>
            )}
        </Row>
    </>
    )
}
