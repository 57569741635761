import React from "react"

import ApplicationFrame from "./ApplicationFrame";

export  default function Page(props) {
    document.title = props.title
    let className = props.title.toLowerCase().replace(" ","-");
    document.body.className = className;

    const PageComponent = props.component

    if(props.requiresAuthentication) {
        if(typeof props.user === 'undefined' || props.user === null) {
            window.location = "/login";
        }
    }
    if(props.requiresAdmin === true && !props.isAdmin) {
        window.location = "/not-authorized";
    }

    return (
      <>
        {process.env.REACT_APP_ENVIRONMENT !== "production" &&
            <div className="enviroment-warning">
                THIS IS A {process.env.REACT_APP_ENVIRONMENT.toUpperCase()} COPY OF THE APP
            </div>
        }

        {props.appFrame === false &&
          <PageComponent {...props}/>
        }

        {props.appFrame !== false &&
            <ApplicationFrame {...props}>
                <div className="container">
                    <div className="row">
                        <div className="column">
                            <PageComponent {...props}/>
                        </div>
                    </div>
                </div>
            </ApplicationFrame>
        }

      </>
    )

}
