import React, {useState} from "react"
import {fetchPagedResource} from "./../../../utils/dataAccess";

export  default function AdminUserProfile(props) {
    const userId = props.id;

    const [isInitiated, setIsInitiated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userProfile, setUserProfile] = useState({});

    function loadUserData() {
        setIsLoading(true);
        setIsInitiated(true);
        fetchPagedResource("users/"+userId)
        .then(data => {
            setUserProfile(data);
            setIsLoading(false);
        })
    }

    if(!isInitiated) {
        loadUserData();
    }

    return (
      <>
        <div className="admin-card">
            <h2>Profile</h2>
            {isLoading || !isInitiated ? (
                <p><em>Loading...</em></p>
            ) : (
                <table className="table">
                    <tbody>
                        <tr><th>Username:</th><td>{userProfile.username}</td></tr>
                        <tr><th>Email:</th><td>{userProfile.email}</td></tr>
                        <tr><th>First Name:</th><td>{userProfile.firstName}</td></tr>
                        <tr><th>Last Name:</th><td>{userProfile.lastName}</td></tr>
                    </tbody>
                </table>
            )}
        </div>
      </>
    )

}
