import React from "react"
import {Row, Column} from 'react-foundation';
import { FaTachometerAlt, FaChevronLeft } from 'react-icons/fa'
import { Link} from "react-router-dom";

export  default function AdminMenu(props) {

    function isPageAdmin() {
        return window.location.pathname.includes("admin");
    }

    return (
      <>
      {props.isAdmin &&
      <div id="admin-toolbar">
        <div className="inner-wrap">
          <Row>
            <Column small={6} medium={4}>
                {isPageAdmin() ? (
                    <Link to="/">
                      <FaChevronLeft/>
                      {" "}
                      Back
                    </Link>
                    ) :
                    (
                    <Link to="/admin/users">
                      <FaTachometerAlt/>
                      {" "}
                      Admin
                    </Link>
                    )
                }
            </Column>
            <Column small={6} medium={8} className="text-right">
              <a href="#" onClick={()=>{props.logOut()}}>Logout</a>
            </Column>
          </Row>
        </div>
      </div>
      }
      </>
    )

}
