import React, { useState } from "react";
import { Link} from "react-router-dom";

import {Row, Column} from 'react-foundation';
import {fetchResource} from "./../../../utils/dataAccess";
import AdminPage from './../AdminPage';

import {FaPlusCircle} from "react-icons/fa"

export  default function AdminCourseList(props) {
    const [isInitiated, setIsInititaed] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [availableCourses, setAvailableCourses] = useState([]);
    const [courseLocks, setCourseLocks] = useState([]);

    function getCourseByName(name) {
        for(let i=0;i<availableCourses.length;i++) {
            if(availableCourses[i].name == name) {
                return availableCourses[i];
            }
        }
        return null;
    }


    if(!isInitiated) {
        setIsInititaed(true);
        // Get List of courses
        fetchResource("written_courses")
            .then(response=>{
                let courses = response['hydra:member'];
                setAvailableCourses(courses);
                setIsLoading(false);
            })
    }

    return (
      <>
        <AdminPage>
            <Row>
                <Column small={12}>
                    <div className="admin-page-header">
                        <h1>Courses</h1>
                        <div className="actions">
                            <button onClick={(e)=>{e.preventDefault(); alert("Not Implemented Yet")}}><FaPlusCircle color={"green"}/>   Add Course</button>
                        </div>
                    </div>
                </Column>
            </Row>
            <div className="">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Course Name</th>
                            <th>Sections</th>
                            <th>Questions</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan={5}>
                                    Loading Course List...
                                </td>
                            </tr>
                        ) : (availableCourses.map(course =>
                            <tr key={course.id}>
                                <td>{course.name}</td>
                                <td>{course.sections.length}</td>
                                <td>{(()=>{
                                    console.log(course);
                                    let count = 0;

                                    course.sections.map(section=>{
                                        count += section.maxQuestionCount;
                                    });

                                    return count;
                                })()}</td>
                                <td><a href="#" onClick={(e)=>{e.preventDefault(); alert("Not implemented yet."); }}>Edit Course ></a></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </AdminPage>
      </>
    )
}
