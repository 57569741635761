import React, {useState} from "react"
import {Row, Column, Progress, Button} from 'react-foundation';
import {updateResource} from "./../../utils/dataAccess";

export  default function WrittenTestQuestion(props) {
    const [providedAnswer, setProvidedAnswer] = useState(props.question.providedAnswer ? props.question.providedAnswer : "")

    function getValue(possibleAnswer) {
        if(props.question.questionType === "true_or_false") {
            if(possibleAnswer == "true") return '1';
            if(possibleAnswer == "false") return '0';
        }
        return possibleAnswer;
    }

    function getText(possibleAnswer) {
        if(props.question.questionType === "true_or_false") {
            if(possibleAnswer == "true") return 'True';
            if(possibleAnswer == "false") return 'False';
        }
        return possibleAnswer;
    }

    function updateProvidedAnswer(answer) {
        updateResource("user_written_course_questions/"+props.question.id,{
            providedAnswer: answer
        })
        .then((data)=>{ 
            setProvidedAnswer(data.providedAnswer);
            props.refresh();
        });
    }

    return (
      <Column small={12} medium={6} className="quiz-question">
        <h3>{props.number}{".) "}{props.question.questionText}</h3>
        <Row className="answer-group">
        { props.question.allPossibleAnswers.map( (answer, index) =>
            <React.Fragment key={answer.id}>
          <Column small={12}>
            <label className="answer">
              <input
                type="radio"
                name={props.question.id}
                style={props.question.questionType === 'true_or_false' ? {'textTransform':'uppercase'} : {}}
                value={getValue(answer)}
                onChange={ (e)=>{
                  updateProvidedAnswer(e.target.value);
                }}
                checked={ (()=>{
                    let answerCopy = answer;
                    return providedAnswer == answerCopy ? true : false;
                })()}
              />

              {" "}
              {String.fromCharCode(65 + index)}.) {getText(answer)}
            </label>
          </Column>
          </React.Fragment>
        )}
        </Row>
      </Column>
    )
}  


