import React, {useState} from "react"
import {Row, Column, Progress, Button} from 'react-foundation';

export  default function WrittenTestToolbar(props) {
    return (
        <Row>
        <Column small={6}>
          <Button className="card-button" onClick={props.onBack}>Back</Button>
        </Column>
        <Column small={6} className="text-right">
          { props.canContinue &&
            <Button className="card-button" onClick={props.onNext}>
              Next
            </Button>
          }
          { !props.canContinue &&
            <Button className="card-button" disabled>
              Next
            </Button>
          }
        </Column>
      </Row>
    )
}
