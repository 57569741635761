import React, {Component} from 'react';

class ModalToggle extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div style={{cursor:'pointer', display:'inline-block', ...this.props.style}} onClick={()=>{this.props.toggleModal()}}>
        {this.props.children}
        </div>
    );
  }
}
export default ModalToggle;
