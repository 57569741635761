import React, {Component} from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('modals');
class OpenModal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.state = {scrollTop: document.querySelector("html").scrollTop};
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.isOpen !== this.props.isOpen) {
        this.setState({scrollTop:document.querySelector("html").scrollTop});
    }
  }

  render() {
    if(!this.props.isOpen) return null;
    return ReactDOM.createPortal(
        (
             <React.Fragment>
                <div onClick={()=>{this.props.toggleModal()}} className="modal-overlay"/>
                <div className={"open-modal"+" "+this.props.className} style={{top:this.state.scrollTop + 50}}>
                    {React.Children.toArray(this.props.children).map((child,index)=>
                        <div key={index}>
                            { React.cloneElement(child, {
                                isOpen              : this.props.isOpen,
                                toggleModal         : this.props.toggleModal
                            })}
                        </div>
                    )}
                </div>
            </React.Fragment>
        ),
      this.el
    );
  }
}
export default OpenModal;
