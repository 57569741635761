import { ENTRYPOINT } from "../config/entrypoint";
import { SubmissionError } from "redux-form";
import get from "lodash/get";
import has from "lodash/has";
import mapValues from "lodash/mapValues";
import authHeader from "./../services/auth-header";

const MIME_TYPE = "application/ld+json";


export function fetch(id, options = {}) {
  if ("undefined" === typeof options.headers) options.headers = new Headers();
  if (null === options.headers.get("Accept"))
    options.headers.set("Accept", MIME_TYPE);

  if (
    "undefined" !== options.body &&
    !(options.body instanceof FormData) &&
    null === options.headers.get("Content-Type")
  )
    options.headers.set("Content-Type", MIME_TYPE);
    if(typeof authHeader.Authorization !== undefined) {
        options.headers.set("Authorization", authHeader().Authorization);
    }

  return global.fetch(new URL(id, ENTRYPOINT), options).then((response) => {
    if (response.ok) return response;

    if(response.status === 401) {
        localStorage.removeItem("user");
        window.location = "/login";
    }

    return response.json().then(
      (json) => {
        const error =
          json["hydra:description"] ||
          json["hydra:title"] ||
          "An error occurred.";
        if (!json.violations) throw Error(error);

        let errors = { _error: error };
        json.violations.forEach((violation) =>
          errors[violation.propertyPath]
            ? (errors[violation.propertyPath] +=
                "\n" + errors[violation.propertyPath])
            : (errors[violation.propertyPath] = violation.message)
        );

        throw new SubmissionError(errors);
      },
      () => {
        throw new Error(response.statusText || "An error occurred.");
      }
    );
  });
}

export function mercureSubscribe(url, topics) {
  topics.forEach((topic) =>
    url.searchParams.append("topic", new URL(topic, ENTRYPOINT))
  );

  return new EventSource(url.toString());
}

export function normalize(data) {
  if (has(data, "hydra:member")) {
    // Normalize items in collections
    // data["hydra:member"] = data["hydra:member"].map((item) => normalize(item));

    return data;
  }

  // Flatten nested documents
  return mapValues(data, (value) =>
    Array.isArray(value)
      ? value.map((v) => get(v, "@id", v))
      : get(value, "@id", value)
  );
}

export function extractHubURL(response) {
  const linkHeader = response.headers.get("Link");
  if (!linkHeader) return null;

  const matches = linkHeader.match(
    /<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/
  );

  return matches && matches[1] ? new URL(matches[1], ENTRYPOINT) : null;
}

export function fetchPagedResource(resource, page = 1, filters = []) {
        resource = resource+'?page='+page;
        filters.map((filter)=>{
            resource += "&"+filter.key+"="+filter.value;
        })

        return fetch(resource)
          .then((response) =>
            response
              .json()
              .then((retrieved) => ({ retrieved, hubURL: extractHubURL(response) }))
          )
          .then(({ retrieved, hubURL }) => {
            return retrieved;
          });
}

export function fetchResource(resource, filters = []) {
        if(filters.length > 0) resource += "?v=1";
        filters.map((filter)=>{
            let key   = Object.keys(filter)[0];
            let value = filter[Object.keys(filter)[0]];
            resource += "&"+key+"="+value;
        })

        return fetch(resource)
          .then((response) =>
            response
              .json()
              .then((retrieved) => ({ retrieved, hubURL: extractHubURL(response) }))
          )
          .then(({ retrieved, hubURL }) => {
            return retrieved;
          });
}

export function createResource(resource, data) {
        let options = {
            headers: new Headers(),
            method: 'POST',
            body: JSON.stringify(data)
        };

        return fetch(resource, options)
          .then((response) =>
            response
              .json()
              .then((retrieved) => ({ retrieved, hubURL: extractHubURL(response) }))
          )
          .then(({ retrieved, hubURL }) => {
            return retrieved;
          });
}

export function updateResource(resource, data) {
        let options = {
            headers: new Headers(),
            method: 'PUT',
            body: JSON.stringify(data)
        };

        return fetch(resource, options)
          .then((response) =>
            response
              .json()
              .then((retrieved) => ({ retrieved, hubURL: extractHubURL(response) }))
          )
          .then(({ retrieved, hubURL }) => {
            return retrieved;
          });
}

export function deleteResource(resource) {
        let options = {
            headers: new Headers(),
            method: 'DELETE',
        };

        return fetch(resource, options)
          .then((response) => {
            return response;
          });
}
