import React, {Component} from 'react';
import ModalToggle from './modal-toggle';
import OpenModal from './open-modal';
import AfterModal from './after-modal';
import "./modal.css";

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open:false,
            lastScroll: null
        }
    }

    toggleModal() {
        if(this.state.open) {
            document.querySelector("html").scrollTop = this.state.lastScroll;
        }

        let newState = !this.state.open;
        this.setState({
            open: newState,
            lastScroll : document.querySelector("html").scrollTop 
        },()=>{
            if(newState && typeof this.props.afterOpen === "function") {
                this.props.afterOpen();
            }
            else if(!newState && typeof this.props.afterClose === "function") {
                this.props.afterClose();
            }
        });
    }

    render() {
        return (
             <React.Fragment>
                {React.Children.toArray(this.props.children).map((child,index)=>
                    <React.Fragment key={index}>
                        { React.cloneElement(child, {
                            isOpen              : this.state.open,
                            toggleModal         : this.toggleModal.bind(this)
                        })}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export {Modal, ModalToggle, OpenModal, AfterModal};
