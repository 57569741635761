import React, {useState} from "react"
import {Row, Column, Progress, Button} from 'react-foundation';
import WrittenTestToolbar from './WrittenTestToolbar';
import WrittenTestQuestion from './WrittenTestQuestion';
import VideoEmbed from './VideoEmbed';

export  default function WrittenTestSection(props) {
    const [section, setSection] = useState(null);
    const [isOnVideo, setIsOnVideo] = useState(true);

    // Toggle video on section change
    if(section == null) {
        setSection(props.section);
    }
    else if(props.section['@id'] != section['@id']) {
        setIsOnVideo(true);
        setSection(props.section);
    }

    function areAllQuestionsAnswered() {
        let allQuestionsAnswered = true;
        props.section.questions.map(q=>{
            if(typeof q.providedAnswer === "undefined" || q.providedAnswer === "") {
                allQuestionsAnswered = false;
            }
        });
        return allQuestionsAnswered;
    }

    if(isOnVideo) {
        return(
            <div className="lesson-content">
              <Row>
                <Column small={12}>
                  <h2>{props.section.name.replaceAll("&amp;","&")} - Video</h2>
                </Column>
              </Row>
              <Row>
                <Column small={12} large={2}/>
                <Column small={12} large={8} className="text-center">
                  <VideoEmbed src={props.section.video}/>
                </Column>
              </Row>
              <WrittenTestToolbar
                canContinue={isOnVideo}
                onNext={()=>{
                    if(isOnVideo) {
                        setIsOnVideo(false);
                    }
                    else {
                        props.triggerNext();
                        isOnVideo(true);
                    }
                }}
                onBack={()=>{
                    if(!isOnVideo) {
                        setIsOnVideo(true);
                    }
                    else {
                        props.triggerBack();
                    }
                }}
              />

            </div>
        );
    }
    else {
        return(
            <>
            <Row>
                {props.section.questions.map((question,index) =>
                    <WrittenTestQuestion
                        key={question.id}
                        question={question}
                        number={index+1}
                        refresh={props.refresh}
                    />
                )}
            </Row>
              <WrittenTestToolbar
                canContinue={areAllQuestionsAnswered()}
                onNext={()=>{
                    if(isOnVideo) {
                        setIsOnVideo(false);
                    }
                    else {
                        props.triggerNext();
                    }
                }}
                onBack={()=>{
                    if(!isOnVideo) {
                        setIsOnVideo(true);
                    }
                    else {
                        props.triggerBack();
                    }
                }}
              />
              </>

        )
    }
}
