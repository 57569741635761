import React, {useState} from "react"
import {Row, Column} from 'react-foundation';
import { useForm } from "react-hook-form";

import {createResource} from "./../../../utils/dataAccess";

export  default function CreateUser(props) {
    const [isSubmiting, setIsSubmiting] = useState(false);
    const { register, formState: { errors }, handleSubmit } = useForm();

    const onSubmit = (data) => {
        if(isSubmiting) return false;
        if(data.password != data.password_confirm) {
            alert("Passwords do not match");
            return false;
        }
        setIsSubmiting(true);

        createResource("users", data)
            .then((response) =>{
                // console.log(response);
                props.toggleModal();
                props.refresh();
            });
    }

    return (
      <>
        <h1>Add User</h1>

        {isSubmiting &&
            <h3 className="loading">Creating User...</h3>
        }

        {!isSubmiting &&
        <form onSubmit={handleSubmit(onSubmit)} className="form hook-form">
            <Row>
                <Column small={6}>
                        <label>Username:</label>
                        <input type="text" {...register("username", { required: true, minLength: 4 })} />
                        {errors.username && (<p className="error">Username is required and should be at least 4 characters.</p>)}
                </Column>
                <Column small={6}>
                        <label>Email Address:</label>
                        <input
                            type="email"
                            {...register('email', {
                                required: "Required",
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Please enter a valid email',
                                }
                            })}
                        />
                        {errors.email && <p className="error">A valid email address is required.</p>}
                </Column>
            </Row>
            <Row>
                <Column small={6}>
                        <label>First Name:</label>
                        <input type="text" {...register("firstName", { required: true, minLength: 2 })} />
                        {errors.firstName && <p className="error">First name is required and should be at least 2 characters.</p>}
                </Column>
                <Column small={6}>
                        <label>Last Name:</label>
                        <input type="text" {...register("lastName", { required: true, minLength: 2 })} />
                        {errors.lastName && <p className="error">Last name is required and should be at least 2 characters.</p>}
                </Column>
            </Row>
            <Row>
                <Column small={6}>
                        <label>Password:</label>
                        <input
                            type="password"
                            {...register('password', {
                                required: "Required",
                                minLength: 8
                            })}
                        />
                        {errors.password && <p className="error">A password with at least 8 characters is required.</p>}
                </Column>
                <Column small={6}>
                        <label>Password (Confirm):</label>
                        <input
                            type="password"
                            {...register('password_confirm', {
                            })}
                        />
                </Column>
            </Row>
            <Row>
                <Column small={12}>
                    <input type="submit" className="button button-primary" value="Create User"/>
                </Column>
            </Row>
        </form>
        }

      </>
    )

}
