import React, {useState} from "react"
import {Row, Column} from 'react-foundation';
import { FaPlusCircle } from 'react-icons/fa';
import { Link} from "react-router-dom";

import AdminMenu from './../AdminMenu';
import AdminPage from './../AdminPage';
import CreateUser from './CreateUser';

import {fetchPagedResource, deleteResource} from "./../../../utils/dataAccess";

import {Modal, ModalToggle, OpenModal} from "../../Modal";


export  default function AdminUsers(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);

    function loadUsers() {
        fetchPagedResource("users")
        .then(data => {
            setIsLoading(false);
            setUsers(data['hydra:member']);
        })
    }

    function deleteUser(userId) {
        if(window.confirm("Are you sure?") ==true) {
            deleteResource("users/"+userId)
            .then(response=>{
                loadUsers();
            });
        }
    }

    if(!isLoading && users.length === 0) {
        setIsLoading(true);
        loadUsers();
    }

    return (
      <>
        <AdminPage>
            <Row>
                <Column small={12}>
                    <div className="admin-page-header">
                        <h1>Users</h1>
                        <Modal>
                            <ModalToggle>
                                <div className="actions">
                                    <button><FaPlusCircle color={"green"}/>   Add User</button>
                                </div>
                            </ModalToggle>
                            <OpenModal>
                                <CreateUser refresh={loadUsers}/>
                            </OpenModal>
                        </Modal>
                    </div>
                </Column>
            </Row>
            <div className="">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email Address</th>
                            <th>User Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan={5}>
                                    Loading Users...
                                </td>
                            </tr>
                        ) : (users.map(user =>
                            <tr key={user.id}>
                                <td>{user.username}</td>
                                <td>{user.firstName}</td>
                                <td>{user.lastName}</td>
                                <td>{user.email}</td>
                                <td>{user.roles.includes("ROLE_ADMIN") ? "Admin" : "JR Coach"}</td>
                                <td>
                                    {user.roles.includes("ROLE_ADMIN") ?
                                        (
                                            <a href="#" onClick={()=>{alert('Cant Delete Admin')}} style={{paddingRight:15,opacity:.5}}>Delete</a>
                                        ):
                                        (
                                            <a href="#" onClick={()=>{deleteUser(user.id)}} style={{paddingRight:15}}>Delete</a>
                                        )
                                    }
                                    <Link to={"/admin/user/"+user.id}>
                                        Details >
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </AdminPage>
      </>
    )

}
