import React, { useState } from "react";
import { Link} from "react-router-dom";

import {Row, Column} from 'react-foundation';
import {fetchResource} from "./../../utils/dataAccess";

import {FaLock, FaCheckCircle} from "react-icons/fa"
import {BsCheckCircle, BsCircle} from "react-icons/bs"
import {FiLock} from "react-icons/fi"

export  default function ListAvailableWrittenTests(props) {
    const [isInitiated, setIsInititaed] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [availableCourses, setAvailableCourses] = useState([]);
    const [courseGrades, setCourseGrades] = useState([]);
    const [courseLocks, setCourseLocks] = useState([]);

    function hasGrade(id) {
        for(let i=0;i < courseGrades.length; i++) {
            if(courseGrades[i].writtenCourseId == id) {
                return true;
            }
        }
        return false;
    }

    function getCourseGrade(id) {
        for(let i=0;i<courseGrades.length;i++) {
            if(courseGrades[i].writtenCourseId == id) {
                return courseGrades[i].grade;
            }
        }
        return 0;
    }

    function getCourseByName(name) {
        for(let i=0;i<availableCourses.length;i++) {
            if(availableCourses[i].name == name) {
                return availableCourses[i];
            }
        }
        return null;
    }


    function isCourseLocked(id) {
        let courseName = "";
        availableCourses.forEach(course=>{
            if(course.id == id) {
                courseName = course.name;
            }
        });

        if(courseName == "Building Level 2 - Written") {
            let parentCourse = getCourseByName("Building Level 1 - Written");
            if(parentCourse === null || !hasGrade(parentCourse.id) || getCourseGrade(parentCourse.id) < 70) {
                return true;
            }
        }
        else if(courseName == "Tumbling Level 2 - Written") {
            let parentCourse = getCourseByName("Tumbling Level 1 - Written");
            if(parentCourse === null || !hasGrade(parentCourse.id) || getCourseGrade(parentCourse.id) < 70) {
                return true;
            }
        }
        return false;
    }

    if(!isInitiated) {
        setIsInititaed(true);
        // Get List of courses
        fetchResource("written_courses")
            .then(response=>{
                let courses = response['hydra:member'];

                setAvailableCourses(courses);

                let gradesToFetch = []
                courses.forEach((element, i) => {
                    gradesToFetch.push(
                        fetchResource("written_course/"+element.id+"/grade")
                    );
                });
                return Promise.all(gradesToFetch);
            })
            .then(responses=>{
                let newCourseGrades = [];
                responses.forEach(response=>{
                    if(response.isGraded) {
                        newCourseGrades.push(response);
                    }
                });
                setCourseGrades(newCourseGrades);

                // Get course Locks
                setIsLoading(false);
            });

        //
        // Get Grades for users
        // Check if user is allowed to takes coures
    }

    if(isLoading) return(
        <h3 className="loading">Loading Course List...</h3>
    );

    return (
      <div>
        <table>
            <thead>
                <tr>
                    <th>Course Name</th>
                    <th>Grade</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {availableCourses.map(course=>
                    <tr key={course.id}>
                        <td>
                            <span style={{display:'inline-block', minWidth:25}}>
                            {isCourseLocked(course.id) ?
                                (
                                    <FiLock/>
                                ):
                                (
                                    <>
                                        {hasGrade(course.id) ? 
                                            (
                                                <BsCheckCircle style={{color:'green'}}/>
                                            ):
                                            (
                                                <BsCircle style={{color:'green'}}/>
                                            )
                                        }
                                    </>
                                )
                            }
                            </span>

                            {course.name}
                        </td>
                        <td>{getCourseGrade(course.id)}%</td>
                        <td style={{textAlign:'right'}}>
                            {isCourseLocked(course.id) ? (
                                    <button disabled className="button button-primary">Start Course</button>
                                ) :
                                (
                                    <Link to={"written-course/"+course.id}>
                                        <button className="button button-primary">Start Course</button>
                                    </Link>
                                )
                            }
                        </td>

                    </tr>
                )}
            </tbody>
        </table>
      </div>
    );
}
