import axios from "axios";
import {ENTRYPOINT} from '../config/entrypoint';

class AuthService {
  login(username, password) {
    return axios
      .post(ENTRYPOINT + "authentication", { username, password })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(ENTRYPOINT + "signup", {
      username,
      email,
      password,
    });
  }
}

export default new AuthService();
