import React from "react"
import moment from "moment";

import Header from './Header';

export  default function ApplicationFrame(props) {
    return (
      <>
        <Header {...props}/>

        <main>
            {props.children}
        </main>

        {!props.hideFooter &&
            <footer className="main">
                <div className="bottom-area">
                    <div className="row label-content-gap">
                        <div className="columns small-12">
                            <div className="horizontal-line"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="columns small-6">
                            <div>&copy; {moment().format("YYYY")} USASF All Rights Reserved</div>
                        </div>
                        <div className="columns footer-links text-right small-6">
                            <a href="mailto:credentialing@usasf.net">Help Desk</a>
                            <a href="/terms-of-use">Terms of Use</a>
                            <a href="/privacy-policy">Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </footer>
        }
      </>
    )

}
