import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import user from './user';
import order from './order';

export default combineReducers({
  auth,
  message,
  user,
  order
});
