import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";

import './scss/app.scss';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import Login from "./components/Login";
import Home from "./components/Home";

import { logout } from "./actions/auth";

import { history } from './helpers/history';

import Page from './components/Page';

import {AdminUsers, NotAuthorized, AdminUserDetails, AdminCourseList} from './components/Admin';
import NotFound from './components/NotFound';

import {WrittenTest} from "./components/WrittenTest";
import UserProfile from "./components/UserProfile";

class App extends Component {
  logOut() {
    this.props.dispatch(logout())
  }

  pageDateInjector(props) {
    return {
        logOut: ()=>{this.logOut()},
        user: this.props.user,
        isAdmin: this.props.isLoggedIn && this.props.user.roles.includes("ROLE_ADMIN") ? true : false,
        ...this.props,
        ...props
    }
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/login"
              render={props => (
                  <Page {...this.pageDateInjector(props)} component={Login} title="Login" appFrame={false} />
                )
              }
          />
          <Route exact path="/"
              render={props => (
                  <Page {...this.pageDateInjector(props)} requiresAuthentication={true} component={Home} title="Home" />
                )
              }
          />

          <Route exact path="/profile"
              render={props => (
                  <Page {...this.pageDateInjector(props)} requiresAuthentication={true} component={UserProfile} title="User Profile" />
                )
              }
          />

          <Route exact path="/written-course/:id"
              render={props => (
                  <Page {...this.pageDateInjector(props)} requiresAuthentication={true} component={WrittenTest} title="Written Test" />
                )
              }
          />

          <Route exact path="/admin/users"
              render={props => (
                  <Page {...this.pageDateInjector(props)} requiresAuthentication={true} requiresAdmin={true} component={AdminUsers} hideHeader={true} hideFooter={true}  title="Users" />
                )
              }
          />
          <Route path="/admin/user/:id"
              render={props => (
                  <Page {...this.pageDateInjector(props)} requiresAuthentication={true} requiresAdmin={true} component={AdminUserDetails} hideHeader={true} hideFooter={true}  title="User Details" />
                )
              }
          />
          <Route exact path="/admin/courses"
              render={props => (
                  <Page {...this.pageDateInjector(props)} requiresAuthentication={true} requiresAdmin={true} component={AdminCourseList} hideHeader={true} hideFooter={true}  title="Course Lists" />
                )
              }
          />


          <Route exact path="/not-authorized"
              render={props => (
                  <Page {...this.pageDateInjector(props)} requiresAuthentication={true} component={NotAuthorized} title="Not Authorized" />
                )
              }
          />
          <Route
              render={props => (
                  <Page {...this.pageDateInjector(props)} component={NotFound} title="Not Found" />
                )
              }
          />
        </Switch>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { isLoggedIn } = state.auth;
  return {
    user,
    isLoggedIn
  };
}

export default connect(mapStateToProps)(App);
