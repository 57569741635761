import React, { Component } from "react";

import {ListAvailableWrittenTests} from "./WrittenTest";

export  default function Home(props) {
    return (
      <div className="">
        <div className="row title-container">
            <div className="columns small-12 medium-6 large-6">
                <div className="title">Welcome back, {props.user.firstName}!</div>
            </div>
        </div>
        <ListAvailableWrittenTests/>


      </div>
    );
}
