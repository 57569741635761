import React from "react"
import { Link } from "react-router-dom";
import {Row, Column} from 'react-foundation';

import {AdminToolbar} from './Admin';
import logo from "../images/usasf-logo.jpg";

import {FaChevronDown} from 'react-icons/fa';

import Gravatar from 'react-gravatar'

export  default function Header(props) {
    return (
      <>
        <AdminToolbar {...props}/>
        {!props.hideHeader &&
            <header id="top-header">
                <div className="top-bar outerpadding">
                    <div className="top-bar-left">
                        <Link to="/" className="logo-link">
                            <img style={{paddingRight:15}} src={logo} className="logo"/>
                            <h1 style={{}}>
                                JR Credentialing
                            </h1>
                        </Link>
                    </div>
                    <div className="top-bar-right">

                  <div className="profile nav-dropdown-top">
                    <div className="top-bar-alignment">
                        <div className="name-field">
                            <div className="name">
                                {props.user.firstName + " " + props.user.lastName}
                            </div>
                        </div>

                        <div className="chevron-wrap">
                            <FaChevronDown/>
                        </div>

                        <div className="avatar-wrap">
                            <Gravatar email={props.user.email} className="avatar-image"  size={75}/>
                        </div>
                    </div>


                    <div className="nav-dropdown-wrap">
                      <div className="nav-indicator" />
                      <div className="nav-dropdown">
                        <ul className="menu">
                            <li><Link to="/profile">Profile</Link></li>
                            <li><a href="#" onClick={(e)=>{props.logOut()}}>Logout</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>



                    </div>
                </div>
            </header>
        }
      </>
    )
}
