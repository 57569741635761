import React, {useState} from "react"
import {fetchResource} from "./../../../utils/dataAccess";
import moment from 'moment'

export  default function AdminUserWrittenTestHistory(props) {
    const userId = props.id;

    const [isInitiated, setIsInitiated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [testHistory, setTestHistory] = useState({});

    function loadUserData() {
        setIsLoading(true);
        setIsInitiated(true);
        fetchResource("user_written_courses/",[{user: userId}])
        .then(data => {
            setTestHistory(data['hydra:member']);
            setIsLoading(false);
        })
    }

    if(!isInitiated) {
        loadUserData();
    }

    return (
      <>
        <div className="admin-card">
            <h2>Written Test History</h2>
            {isLoading || !isInitiated ? (
                <p><em>Loading...</em></p>
            ) : (
                <table className="table">
                    <thead>
                        <tr>
                            <td>Course</td>
                            <td>Status</td>
                            <td>Started</td>
                            <td>Last Modified</td>
                            <td>Grade</td>
                        </tr>
                    </thead>
                    <tbody>
                        {testHistory.map(history=> 
                            <tr key={history.id}>
                                <td>{history.name}</td>
                                <td style={{textTransform:"capitalize"}}>{history.status}</td>
                                <td>{moment(history.created).format("MM/DD/YYYY h:mm a")}</td>
                                <td>{moment(history.updated).format("MM/DD/YYYY h:mm a")}</td>
                                <td>{history.grade ? history.grade+"%" : "N/A"}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
        </div>
      </>
    )

}
